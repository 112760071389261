/*
 * hoverable.js
 * (C)2014 SHINGI Corporation.
 */

	$(function(){
		$("#gmenu img").hoverable();
		$(".infoarea02 .infolink img").hoverable();
		$(".maparea img").hoverable();
	});

